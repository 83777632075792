var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("p", [
                  _vm._v(
                    "Showing " +
                      _vm._s(_vm.start) +
                      " - " +
                      _vm._s(_vm.end) +
                      " of " +
                      _vm._s(_vm.total)
                  )
                ])
              ]),
              _c("b-col")
            ],
            1
          )
        ],
        1
      ),
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          small: "",
          "sticky-header": "300px",
          fields: _vm.fields,
          items: _vm.messages,
          "primary-key": "id",
          "tbody-tr-class": _vm.rowClass
        },
        on: { "row-clicked": _vm.selectMessage },
        scopedSlots: _vm._u([
          {
            key: "cell()",
            fn: function(data) {
              return [
                !_vm.isViewed(data.item)
                  ? _c("strong", [_vm._v(_vm._s(data.value))])
                  : _c("span", [_vm._v(_vm._s(data.value))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }