var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "text-md-left text-center",
              attrs: { cols: "12", md: "6", lg: "4", xl: "3" }
            },
            [
              _c("span", { staticClass: "h4 text--white" }, [
                _vm._v("Messages")
              ])
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "text-right",
              attrs: {
                cols: "12",
                md: "6",
                lg: "4",
                "offset-lg": "4",
                xl: "3",
                "offset-xl": "6"
              }
            },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.showCompose = true
                    }
                  }
                },
                [_vm._v(" New Message ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("MessageList", {
        attrs: { messages: _vm.messages, total: _vm.total },
        on: {
          selectMessage: _vm.selectMessage,
          compose: function($event) {
            _vm.showCompose = true
          }
        }
      }),
      _vm.selectedMessage
        ? _c("MessageView", {
            attrs: { "message-id": +_vm.selectedMessage.id },
            on: {
              reply: function($event) {
                _vm.showReply = true
              }
            }
          })
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            id: "compose",
            size: "xl",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [_vm._v(" Compose New Message ")]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showCompose,
            callback: function($$v) {
              _vm.showCompose = $$v
            },
            expression: "showCompose"
          }
        },
        [
          _c("Compose", {
            on: {
              close: function($event) {
                return _vm.$bvModal.hide("compose")
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            id: "reply",
            size: "xl",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _vm._v(
                    " Reply To " + _vm._s(_vm.selectedMessage.company) + " "
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showReply,
            callback: function($$v) {
              _vm.showReply = $$v
            },
            expression: "showReply"
          }
        },
        [
          _c("Compose", {
            attrs: {
              "company-id": +_vm.selectedMessage.company_id,
              "message-id": +_vm.selectedMessage.id,
              "transaction-id": +_vm.selectedMessage.plaidtransaction_id
            },
            on: {
              close: function($event) {
                return _vm.$bvModal.hide("reply")
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }