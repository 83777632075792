var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.send.apply(null, arguments)
            },
            reset: function($event) {
              $event.preventDefault()
              return _vm.reset.apply(null, arguments)
            }
          }
        },
        [
          _vm.companies && _vm.companies.length
            ? _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Company:",
                    "label-for": "company",
                    "label-cols": "4",
                    "label-cols-sm": "3",
                    "label-cols-md": "2",
                    "label-align": "right"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.companies,
                      disabled:
                        _vm.companies.length === 1 &&
                        +_vm.message.company_id > 0
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c(
                                "b-form-select-option",
                                { attrs: { value: null, disabled: "" } },
                                [_vm._v("-- Please select a company --")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1351801080
                    ),
                    model: {
                      value: _vm.message.company_id,
                      callback: function($$v) {
                        _vm.$set(_vm.message, "company_id", $$v)
                      },
                      expression: "message.company_id"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Subject:",
                "label-for": "subject",
                "label-cols": "4",
                "label-cols-sm": "3",
                "label-cols-md": "2",
                "label-align": "right"
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "subject",
                  type: "text",
                  placeholder: "Subject of Message",
                  required: ""
                },
                model: {
                  value: _vm.message.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "subject", $$v)
                  },
                  expression: "message.subject"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Message:",
                "label-for": "message",
                "label-cols": "4",
                "label-cols-sm": "3",
                "label-cols-md": "2",
                "label-align": "right"
              }
            },
            [
              _c("Editor", {
                attrs: {
                  "api-key": "83u69vpf23jbsp27k3ufq78a7fp4jxwdd5xhrtbkhpueukuu",
                  init: _vm.tinymceConfig
                },
                model: {
                  value: _vm.message.content,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "content", $$v)
                  },
                  expression: "message.content"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: "Documents:",
                "label-for": "documents",
                "label-cols": "4",
                "label-cols-sm": "3",
                "label-cols-md": "2",
                "label-align": "right"
              }
            },
            [
              _c("b-form-file", {
                attrs: { multiple: "" },
                model: {
                  value: _vm.message.files,
                  callback: function($$v) {
                    _vm.$set(_vm.message, "files", $$v)
                  },
                  expression: "message.files"
                }
              })
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "modal-footer",
              attrs: { "align-h": "center", align: "right" }
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "arrow-clockwise" } }),
                      _vm._v(" Reset ")
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { type: "button", variant: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "x" } }),
                      _vm._v(" Cancel ")
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { type: "submit", variant: "primary" }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "check" } }),
                      _vm._v(" Send ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }