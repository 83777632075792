var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message && _vm.message.id
    ? _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-card",
            { staticClass: "my-3 pb-3", attrs: { "no-body": "" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "b-table-simple",
                        { staticClass: "text-left mt-3", attrs: { small: "" } },
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-td", { staticClass: "text-right" }, [
                                _vm._v("Company:")
                              ]),
                              _c("b-td", [_vm._v(_vm._s(_vm.message.company))])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-td", { staticClass: "text-right" }, [
                                _vm._v("From:")
                              ]),
                              _c("b-td", [_vm._v(_vm._s(_vm.message.email))])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-td", { staticClass: "text-right" }, [
                                _vm._v("To:")
                              ]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.message.recipient))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-tr",
                            [
                              _c("b-td", { staticClass: "text-right" }, [
                                _vm._v("Date:")
                              ]),
                              _c("b-td", [_vm._v(_vm._s(_vm.message.created))])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.message.documents && _vm.message.documents.length
                        ? _c("div", [
                            _c("hr"),
                            _c("h4", { staticClass: "text-center m-0" }, [
                              _vm._v("Attachments")
                            ]),
                            _c(
                              "ul",
                              { staticClass: "text-left ml-5" },
                              _vm._l(_vm.message.documents, function(d) {
                                return _c("li", { key: d.id }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: d.url, target: "_blank" }
                                    },
                                    [_vm._v(_vm._s(d.documentname))]
                                  )
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "text-left",
                      attrs: { cols: "12", md: "8" }
                    },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "" } },
                        [
                          _c("b-card-header", [
                            _c("h4", { staticClass: "m-0" }, [
                              _vm._v("Subject: " + _vm._s(_vm.message.subject))
                            ])
                          ]),
                          _c("b-card-text", { staticClass: "m-3" }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.message.content)
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "success" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$emit("reply", _vm.message)
                            }
                          }
                        },
                        [_vm._v("Reply ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }